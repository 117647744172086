export default {
    /**
     * @description 配置信息
     */
    dataTrans: {
        overView: {
            power: '电力监测点',
            water: '水力监测点',
            gas: '燃气监测点',
            fuel: '燃油监测点',
            burning: '燃煤监测点',
            security: '安防监测点',
            science: '环境监测点',
        },
        nodeInformation:{
            statusTimestamp:'设备状态时间',
            deviceBrand:'设备品牌',
            deviceModel:'设备型号',
            deviceManufacturer:'生产厂家代码',
            serialNo:'机身编号',
            installationAreaId:'安装区域',
            installationLocation:'安装位置',
            lng:'经度',
            lat:'纬度',
            deviceFlag:'设备类别',
            status:'设备状态',
            nickname:'设备名称',
            deviceType:'设备类型',
            companyId:'设备所属企业id',
            id:'设备id',
            sv:'额定电压',
            pid: '父设备id',
            userAccountNumber: '用户户号',
            totalFlag: '总能耗标识',
            subDeviceName:'子设备名称',
            accessPlatform:'接入云平台',
            activeCollectDataFlag:'是否主动采集数据',
            collectCommand:'采集指令',
            communicationMode:'通讯方式',
            factoryInfo:'生产厂家信息',
            remoteCloseCommand:'远程关闭指令',
            remoteControlFlag:'是否具有远程控制功能',
            remoteOpenCommand:'远程开启指令',
            sceneCode:'解析规则',
            supportedProtocolsSubnet:'支持子网协议',
            supportedProtocolsUp:'支持上行协议',
            remark:'备注信息',
        },
        devInformation:{
            address:'安装位置',
            area:'安装区域',
            brand:'设备品牌',
            type:'设备类型',
            construct:'维保单位',
            firm:'生产厂商',
            installTime:'安装日期',
            linkUser:'负责人',
            maintain:'保养周期',
            marking:'设备型号',
            name:'设备名称',
            outTime:'生产日期',
            runStatus:'运行状态',
            serviceTime:'售后截至日期',
            useStatus:'使用状态',            
            longitude:'经度',
            latitude:'纬度',
            children:'子设备',
            companyName:'公司名称',
        },
        fireLevel:{
            '1':'一级',
            '2':'二级',
            '3':'三级',
            '4':'四级',
        },
    },
}