import Vue from 'vue'
import config from './dataTrans'

const {
    dataTrans
} = config;
Vue.filter('dataTrans', function (val,key) {
    var  temp = dataTrans[key];
    return temp[val];
})
