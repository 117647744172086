import axios from 'axios'
import qs from 'qs'
// import { Message } from "element-ui"


// axios.defaults.withCredentials=true
// axios.defaults.crossDomain=true


//配置请求超时时间
axios.defaults.timeout = window.gurl.AXIOS_TIMEOUT;

//配置URL地址
if (process.env.NODE_ENV === 'production') {//生产环境地址
  axios.defaults.baseURL = window.gurl.SERVICE_CONTEXT_PATHAPI
} else if (process.env.NODE_ENV === 'development') {//开发环境地址
  // 测试线上地址
  // axios.defaults.baseURL = ' http://admin.zkcncloud.com/gateway'
  axios.defaults.baseURL = 'http://192.168.0.248:9000/mgt'
  // axios.defaults.baseURL = '/myapi'

} else {//测试环境地址
  axios.defaults.baseURL = '//api.fingercode.cn:9001/gateway'
}


axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use((req) => {// 请求拦截器
  const token = localStorage.getItem('token');
  if (token) {
    req.headers.satoken = token;
  }
  if (req.method === 'delete') {
    req.data = qs.stringify(req.data);
  }
  return req;
});

axios.interceptors.response.use((res) => {// 响应拦截器
  if (res.data.code === 20011 || res.data.code === 20012 || res.data.code === 20013 || res.data.code === 20010) {
    // this.$message.error('登录状态过期！');
    localStorage.removeItem("token");
    localStorage.removeItem("ssh");
    localStorage.removeItem("intelligence");
    location.replace('/')//退出登录
  } else {
    return res.data;
  }
},
  (error) => {
    // console.log("err: " + error)
    let { message } = error
    if (message === "Network Error") {
      message = "后端接口连接异常"
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时"
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常"
    }
    // Message.error(message)
    console.log(message);
    return  {
      code:500,
      message:message,
      success:false,
    }
  }

)

