import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'
// import echarts from 'echarts'
import './assets/css/base.css'
import './components/monitorCommon/mon-filter.js';
import '@/assets/js/directives'
import '@/apis/axiosConfig'

// Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')