import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: '/login',
    component: () => import('../views/Login'),
  },  
  {
    path: '/',
    component: () => import('../views/home'),
    children: [
      {
        path: '/index',
        component: () => import('../views/index'),
      },
      {
        path: '/companyProfile',
        component: () => import('../views/companyProfile'),
      },
      {
        path: '/energy',
        component: () => import('../views/Power/energy'),
      },
      {
        path: '/intelligence',
        component: () => import('../views/IntelligenceOps/intelligence'),
      },
      {
        path: '/security',
        component: () => import('../views/SaveMangement/index'),
      },
      {
        path: '/reduce',
        component: () => import('../views/PowerSaving'),
      },

    ],
  },
  {
    path: "*",
    redirect: "/"
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach(function (to, from, next) {
  if (!localStorage.getItem('token')) {
    if (to.path != '/login') {
      return next('/login');
    }
  }
  next();
})
export default router
